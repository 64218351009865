import React, { useState, useEffect } from 'react'
import ReactDOM from "react-dom";
import HubspotForm from 'react-hubspot-form'

const GartnerGetStarted = () => {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const closeModal = () => setOpen(false)
  return (
    <>
        <div className={open ? 'absolute top-0 left-0 w-full h-full bg-gray-500 z-40 opacity-40' : 'z-0'}></div>
        <div className={open ? 'w-full h-full flex justify-center items-center fixed top-0 left-0 z-50 PopUp overflow-y-scroll lg:overflow-hidden' : 'hidden z-0'}>
            <div className='bg-white rounded-xl shadow p-6 text-7xl relative transform scale-75 md:scale-100 '>
            <div className='absolute right-0 top-3 px-10 py-1'><p onClick={closeModal} className='cursor-pointer text-3xl font-bold p-0 m-0 text-gray-500'>X</p></div>
                <div class = "flex justify-center items-center gartnerForm w-full my-3">
                        <HubspotForm
                        portalId='6153052'
                        formId='c54f54b9-3022-46ee-80f9-6aa4e2695190'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                </div>
            </div>
        </div>
        <section  className='w-full my-4 md:my-10'>
            <div className='w-8/12 md:w-3/12 mx-auto'>
                <button className='vc-new-orange-btn mx-auto' onClick={handleOpen}>Get Started</button>
            </div>
        </section>
    </>
  )
}
const renderGartnerGetStarted=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#gartnergetstarted")
        if (id != null){
            ReactDOM.render(
                <GartnerGetStarted />,
                id
            )
        }
    }
}

setTimeout(renderGartnerGetStarted,100)

export default GartnerGetStarted